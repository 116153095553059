export const deCommon = {
  title: "Mein Profil",
  links: {
    event: "Event",
    location: "Ort",
    tickets: "Tickets",
    gallery: "Fotos",
    details: "Event",
    home: "Home",
    contact: "Kontakt",
    imprint: "Impressum",
    info: "Wir",
    privacy: "Datenschutz",
  },
  footer: {
    contact: "Kontakt",
    info: "Info",
    privacy: "Datenschutz",
    imprint: "Impressum",
  },
  events: {
    location: "Nostromo Görlitz",
    date: "23.09",
    genre: "| Trommelbassmusik | Techno |",
    tickets: "Tickets",
    info: "Info",
    lastEvent: "Nächstes Event: Abyss 16.09.23",
    seeya: "Aktuell keine Veranstaltungen.",
    nextEvent: "Nächstes Event",
    advanceBooking: "Kein Vorverkauf und Onlineverkauf",
  },
  location: {
    location: "Nächster Ort",
    addressTitle: "NOSTROMO Schall & Rauch e.V.",
    addressStreet: "Cottbuser Str. 21, 02826 Görlitz",
    date: "23.09 | 20:00",
  },
  details: {
    price: "15€ Eintritt",
    lineup: "Line Up",
    tickets: "Tickets",
    location: "Ort",
    map: "Karte",
    acts: "Künstler",
    timetable: "Timetable"
  },
  imprint: {
    imprint: "Impressum",
    tmg: "Gemäß § 5 TMG",
    represent: "Vertreten durch",
    contact: "Kontakt",
    content: "Haftung für Inhalte",
    links: "Haftung für Links",
    validity: "Gültigkeit",
  },
  privacy: {
    title: "Datenschutzerklärung",
    common: "Allgemeine Informationen über die Erhebung personenbezogener Daten",
    paragraph1: "Unsere Website selbst erhebt und speichert keinerlei personenbezogene Daten, hält keine Benutzerkonten und speichert keine Cookies. Scripte von externen Dritten zum Zweck des Trackings des Nutzerverhaltens werden ebenso nicht verwendet. ",
    paragraph2: "Es werden einzig und allein Logdateien von den Anfragen durch unseren Hoster angelegt, welche zur Fehlersuche dienen und nicht weitergehend ausgewertet werden. Dazu zählen Browser Meta Daten wie IP-Adresse, Browser, Betriebssystem, Datum und Uhrzeit der Anfrage, Inhalt der Anfrage ( Seite ), HTTP-Statuscode der Anfrage, Übertragene Bytes der angeforderten URL, Art des Endgerätes. Diese Daten werden 14 Tagen temporär gespeichert um den reibungslosen Betrieb der Website zu gewährleisten.",
    hoster: "Hoster",
    paragraph3: "Anbieter ist die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg\n" +
        "(nachfolgend AWS).\n" +
        "Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS\n" +
        "verarbeitet. Hierbei können auch personenbezogene Daten an das Mutterunternehmen von AWS in die USA\n" +
        "übermittelt werden. Die Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln gestützt.\n" +
        "Details finden Sie hier:\n",
    following: "Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS:\n",
    paragraph4: "Die Verwendung von AWS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes\n" +
        "Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende\n" +
        "Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a\n" +
        "DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf\n" +
        "Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die\n" +
        "Einwilligung ist jederzeit widerrufbar.\n",
    evilStates: "Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten",
    paragraph5: "Wir hosten zwar in der AWS Region EU-Central-1 (Frankfurt), können aber weder sicher gehen noch dafür Gewähr nehmen und haften, dass Daten intern von AWS auch in die USA oder andere Drittstaaten übertragen werden könnten. Wir weisen darauf\n" +
        "hin, dass in den USA oder anderen Drittstaaten kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann.\n" +
        "Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden\n" +
        "herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht\n" +
        "ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu\n" +
        "Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese\n" +
        "Verarbeitungstätigkeiten keinen Einfluss.\n",
    rights: "Rechte",
    paragraph6: "Abgesehen der oben genannten Logdateien speichern wir keine personenbezogenen Daten über Sie. Sie haben dennoch alle Ihnen dafür zur Verfügung stehenden Rechte. Diese wären:",
    right1: "Recht auf Auskunft",
    right2: "Recht auf Berichtigung oder Löschung",
    right3: "Recht auf Einschränkung der Verarbeitung",
    right4: "Recht auf Widerspruch gegen die Verarbeitung",
    right5: "Recht auf Datenübertragbarkeitt.",
    right6: "Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die Verarbeitung Ihrer personenbezogenen Daten durch uns zu beschweren.",
    last: "Wir nehmen den Datenschutz ihrer persönlichen Daten ernst und stehen Ihnen daher jederzeit bei Fragen und Anfragen zur Hilfe bereit. Kontaktieren können Sie uns unter ",
  },
  contact: {
    who: "Wer sind",
    nocturnalDemons: "Nocturnal Demons",
    paragraph1:
      "wurde 2022, durch die gemeinsame Leidenschaft zum Drum n Bass und Techno, als Kollektiv von Freunden gegründet, um euch in unserer Region um Görlitz etwas frisches zu liefern, vor allem Neurofunk.",
    paragraph3:
      "Unsere Mission ist es die DnB und Techno Szene zu stärken, indem wir aufstrebenden internationalen DJs\n" +
      "            aus der Tschechischen Republik und anderen Ländern, welche die Neurofunk Szene prägen, zu uns einladen.\n" +
      "            Diese mixen wir mit lokalen DJs, um unseren Gästen ein möglichst qualitatives und vielseitiges Line-Up, sowie den Künstlern eine Plattform in Deutschland bieten zu können.\n" +
      "            Gemeinsam holen wir den Neurofunk über die Grenze und nach Görlitz.",
    team: "Unser Team",
  },
};
