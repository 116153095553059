export const enCommon = {
  title: "Mein Profil",
  links: {
    event: "Event",
    location: "Location",
    tickets: "Tickets",
    gallery: "Photos",
    details: "Event",
    home: "Home",
    contact: "Contact",
    imprint: "Imprint",
    info: "About",
    privacy: "Privacy",
  },
  footer: {
    contact: "Contact",
    info: "Info",
    privacy: "Privacy",
    imprint: "Imprint",
  },
  events: {
    location: "Nostromo Görlitz",
    date: "23.09",
    genre: "| Drum n Bass | Techno |",
    tickets: "Tickets",
    info: "Info",
    lastEvent: "Last event: Abyss 16.09.23",
    seeya: "Currently no Events available",
    nextEvent: "Next Event",
    advanceBooking: "No advance booking and online sale",
  },
  location: {
    location: "Next Location",
    addressTitle: "NOSTROMO Schall & Rauch e.V.",
    addressStreet: "Cottbuser Str. 21, 02826 Görlitz",
    date: "23.09 | 20:00",
  },
  details: {
    price: "15€ Entrance fee",
    lineup: "Line Up",
    tickets: "Tickets",
    location: "Location",
    map: "Map",
    acts: "Artists",
    timetable: "Timetable"
  },
  imprint: {
    imprint: "Imprint",
    tmg: "Gemäß § 5 TMG",
    represent: "Vertreten durch",
    contact: "Kontakt",
    content: "Haftung für Inhalte",
    links: "Haftung für Links",
    validity: "Gültigkeit",
  },
  privacy: {
    title: "Privacy Statement",
    common: "General information about the collection of personal data",
    paragraph1: "Our website itself does not collect and store any personal data, does not hold any user accounts and does not store any cookies. Scripts from external third parties for the purpose of tracking user behavior are also not used",
    paragraph2: "Only log files of the requests are created by our hoster, which are used for troubleshooting and are not further evaluated. This includes browser meta data such as IP address, browser, operating system, date and time of the request, content of the request (page), HTTP status code of the request, bytes transferred of the requested URL, type of terminal device. This data is temporarily stored for 14 days to ensure the smooth operation of the website.",
    hoster: "Hoster",
    paragraph3: "Our hoster is Amazon Web Services (AWS)\n" +
        "The provider is Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxembourg, Luxembourg.\n" +
        "(hereinafter referred to as AWS).\n" +
        "When you visit our website, your personal data is processed on the servers of AWS.\n" +
        "processed. Personal data may also be transmitted to the parent company of AWS in the USA.\n" +
        "will be transmitted. The data transfer to the USA is based on the EU standard contractual clauses.\n" +
        "Details can be found here:",
    following: "For more information, please refer to AWS's privacy policy:",
    paragraph4: "The use of AWS is based on Art. 6 (1) lit. f DSGVO. We have a legitimate\n" +
        "interest in the most reliable presentation of our website. Insofar as a corresponding\n" +
        "consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a\n" +
        "DSGVO and § 25 para. 1 TTDSG, insofar as the consent allows the storage of cookies or access to\n" +
        "information in the user's terminal device (e.g. device fingerprinting) within the meaning of the TTDSG. The\n" +
        "consent can be revoked at any time.",
    evilStates: "Note on data transfer to the USA and other third countries",
    paragraph5: "Although we host in the AWS region EU-Central-1 (Frankfurt), we can neither be sure nor guarantee and are liable for the fact that data could also be transferred internally from AWS to the USA or other third countries. We point out that\n" +
        "that no level of data protection comparable to that in the EU can be guaranteed in the US or other third countries.\n" +
        "For example, U.S. companies are required to disclose personal data to security authorities without your\n" +
        "without you as the data subject being able to take legal action against this. It can therefore not be\n" +
        "authorities (e.g. intelligence services) to process, evaluate and use your data on US servers for surveillance purposes.\n" +
        "monitoring purposes, evaluate it and store it permanently. We have no influence on these\n" +
        "have any influence on these processing activities.",
    rights: "Rights",
    paragraph6: "Apart from the log files mentioned above, we do not store any personal data about you. Nevertheless, you have all the rights available to you for this purpose. These would be:",
    right1: "Right of access",
    right2: "Right to rectification or deletion",
    right3: "Right to restriction of processing",
    right4: "Right to object to processing",
    right5: "Right to data portability",
    right6: "You also have the right to complain to a data protection supervisory authority about the processing of your personal data by us.",
    last: "We take the data protection of your personal data seriously and are therefore always available to help you with questions and requests. You can contact us at",
  },
  contact: {
    who: "Who are",
    nocturnalDemons: "Nocturnal Demons",
    paragraph1:
      "was founded in 2022 by the common passion for drum n bass and techno as a collective of friends to bring you something fresh in our region around Görlitz, especially neurofunk.",
    paragraph3:
      "Our mission is to strengthen the DnB and Techno scene by inviting talented international DJs from the Czech Republic and other countries that shape the Neurofunk scene. We mix them with local DJs to offer our guests a qualitative and diversified line-up as well as a platform for the artists in Germany. Together we bring neurofunk across the border and to Görlitz.",
    team: "Our Team",
  },
};
