import { LineUp } from "../types/LineUp";

export const lineUpData: LineUp = {
  headliners: ["BEA", "DO NOT DISTURB"],
  stages: [
    {
      name: "DnB",
      acts: [
        {
          name: "P.TER",
          time: "22:00",
          genre: "Jump Up DnB",
        },
        {
          name: "DROPZ",
          time: "23:00",
          genre: "Dancefloor DnB",
        },
        {
          name: "AMIDALA",
          time: "00:00",
          genre: "Neurofunk DnB",
        },
        {
          name: "BEA",
          time: "01:00",
          genre: "Neurofunk DnB",
        },
        {
          name: "B ON RAVE",
          time: "02:00",
          genre: "Neurofunk DnB",
        },
        {
          name: "KEON",
          time: "03:00",
          genre: "Neurofunk DnB",
        },
        {
          name: "OPTIMIZ3D",
          time: "04:00",
          genre: "Neurofunk DnB",
        },
      ],
      timetable: "",
    },
    {
      name: "Techno | Trance",
      acts: [
        {
          name: "SODASELECTA",
          time: "22:00",
          genre: "Trance",
        },
        {
          name: "BECCSLYN 1/2",
          time: "23:00",
          genre: "Trance",
        },
        {
          name: "DO NOT DISTURB",
          time: "00:00",
          genre: "Techno",
        },
        {
          name: "LECKO MIO",
          time: "02:00",
          genre: "Techno",
        },
        {
          name: "ZP",
          time: "03:00",
          genre: "Schranz",
        },
        {
          name: "ZEREMONIENMEISTER",
          time: "04:00",
          genre: "Hard Techno",
        },
      ],
      timetable: "",
    },
  ],
};
