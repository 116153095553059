import { EventInfo } from "../types/EventInfo";

export const events: EventInfo = {
  name: "Eclipse",
  locationShort: "Nostromo Görlitz",
  locationFull: "Nostromo Görlitz",
  address: "Cottbuser Str. 21, 02826 Görlitz",
  date: "21.09.24",
  timeStart: "22:00",
  timeEnd: "05:00",
  genre: "Drum n Bass | Techno | Trance",
  ticketLink: "https://nocturnaldemons.ticket.io/#",
  mapLink:
    "https://www.google.de/maps/place/NOSTROMO+(Schall+%26+Rauch+e.V.)/@51.1543368,14.9693562,453m/data=!3m2!1e3!4b1!4m6!3m5!1s0x4708dc2d5356eabb:0xe2a464ea5f708e9a!8m2!3d51.1543368!4d14.9719311!16s%2Fg%2F11bwfm6_62?entry=ttu",
  imgPromo: "/images/logo/eclipse.webp",
  imgCover: "/images/logo/eclipse.webp",
};
