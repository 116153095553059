export const plCommon = {
  title: "Mein Profil",
  links: {
    event: "Wydarzenie",
    location: "Lokalizacja",
    tickets: "Bilety",
    gallery: "Zdjęcia",
    details: "Wydarzenie",
    home: "Home",
    contact: "Kontakt",
    imprint: "Nadruk",
    info: "O nas",
    privacy: "Ochrona danych",
  },
  footer: {
    contact: "Kontakt",
    info: "Informacje",
    privacy: "Ochrona danych",
    imprint: "Nadruk",
  },
  events: {
    location: "Nostromo Görlitz",
    date: "23.09",
    genre: "| Drum n Bass | Techno |",
    tickets: "Bilety",
    info: "Informacje",
    lastEvent: "Ostatnie wydarzenie: Abyss 16.09.23",
    seeya: "Nadchodzi więcej.",
    nextEvent: "następne wydarzenie",
    advanceBooking: "Brak wcześniejszej rezerwacji i sprzedaży online",
  },
  location: {
    location: "Next Location",
    addressTitle: "NOSTROMO Schall & Rauch e.V.",
    addressStreet: "Cottbuser Str. 21, 02826 Görlitz",
    date: "23.09 | 20:00",
  },
  details: {
    price: "15€ Entrance fee",
    lineup: "Skład",
    tickets: "Bilety",
    location: "Lokalizacja",
    map: "Mapa",
    acts: "Artyści",
    timetable: "harmonogram"
  },
  imprint: {
    imprint: "Nadruk",
    tmg: "Gemäß § 5 TMG",
    represent: "Vertreten durch",
    contact: "Kontakt",
    content: "Haftung für Inhalte",
    links: "Haftung für Links",
    validity: "Gültigkeit",
  },
  privacy: {
    title: "Polityka prywatności",
    common: "Ogólne informacje o gromadzeniu danych osobowych",
    paragraph1: "Nasza strona internetowa nie gromadzi ani nie przechowuje żadnych danych osobowych, nie posiada żadnych kont użytkowników i nie przechowuje żadnych plików cookie. Skrypty od zewnętrznych stron trzecich w celu śledzenia zachowań użytkowników również nie są używane. ",
    paragraph2: "Tylko pliki dziennika żądań są tworzone przez naszego hostera, które są wykorzystywane do rozwiązywania problemów i nie są dalej analizowane. Obejmuje to metadane przeglądarki, takie jak adres IP, przeglądarka, system operacyjny, data i godzina żądania, treść żądania (strona), kod stanu HTTP żądania, bajty przesłane z żądanego adresu URL, typ urządzenia końcowego. Dane te są tymczasowo przechowywane przez 14 dni w celu zapewnienia sprawnego działania strony internetowej.",
    hoster: "hostingodawcą",
    paragraph3: "Naszym hostingodawcą jest Amazon Web Services (AWS)\n" +
        "Dostawcą jest Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg\n" +
        "(zwana dalej AWS).\n" +
        "Gdy użytkownik odwiedza naszą witrynę, jego dane osobowe są przetwarzane na serwerach AWS.\n" +
        "serwery. Dane osobowe mogą być również przekazywane do spółki dominującej AWS w USA.\n" +
        "USA. Przekazywanie danych do USA odbywa się na podstawie standardowych klauzul umownych UE.\n" +
        "Szczegóły można znaleźć tutaj:",
    following: "Więcej informacji można znaleźć w polityce prywatności AWS:",
    paragraph4: "Korzystanie z AWS opiera się na art. 6 ust. 1 lit. f DSGVO. Mamy uzasadniony\n" +
        "uzasadniony interes w jak najbardziej niezawodnej prezentacji naszej strony internetowej. W zakresie, w jakim\n" +
        "o zgodę, przetwarzanie odbywa się wyłącznie na podstawie art. 6 ust. 1 lit. a DSGVO i § 25 ust. 1 lit. a DSGVO.\n" +
        "DSGVO i § 25 ust. 1 TTDSG, o ile zgoda umożliwia przechowywanie plików cookie lub dostęp do\n" +
        "informacji w urządzeniu końcowym użytkownika (np. pobieranie odcisków palców urządzenia) w rozumieniu TTDSG. Zgoda\n" +
        "Zgoda może zostać cofnięta w dowolnym momencie.",
    evilStates: "Uwaga dotycząca przekazywania danych do USA i innych krajów trzecich",
    paragraph5: "Chociaż hostujemy w regionie AWS EU-Central-1 (Frankfurt), nie możemy być pewni ani zagwarantować i nie ponosimy odpowiedzialności za fakt, że dane mogą być również przesyłane wewnętrznie z AWS do USA lub innych krajów trzecich. Zwracamy uwagę\n" +
        "Chcielibyśmy podkreślić, że w USA lub innych krajach trzecich nie można zagwarantować poziomu ochrony danych porównywalnego z poziomem w UE.\n" +
        "Na przykład, firmy amerykańskie są zobowiązane do przekazywania danych osobowych organom bezpieczeństwa bez Twojej zgody.\n" +
        "bez możliwości podjęcia kroków prawnych przez osobę, której dane dotyczą. W związku z tym nie można\n" +
        "Władze USA (np. służby wywiadowcze) mogą przetwarzać, analizować i wykorzystywać dane użytkownika na serwerach w USA do celów inwigilacji.\n" +
        "mogą przetwarzać, analizować i wykorzystywać dane użytkownika na serwerach amerykańskich do celów nadzoru, oceny i trwałego przechowywania. Nie mamy wpływu na te działania\n" +
        "Nie mamy wpływu na te czynności przetwarzania.",
    rights: "Prawa",
    paragraph6: "Poza wspomnianymi powyżej plikami dziennika nie przechowujemy żadnych danych osobowych użytkownika. Użytkownikowi nadal przysługują wszystkie dostępne prawa. Są to:",
    right1: "Prawo dostępu",
    right2: "prawo do sprostowania lub usunięcia danych",
    right3: "prawo do ograniczenia przetwarzania",
    right4: "prawo do wniesienia sprzeciwu wobec przetwarzania",
    right5: "prawo do przenoszenia danych.",
    right6: "Użytkownik ma również prawo do złożenia skargi do organu nadzorczego ds. ochrony danych w związku z przetwarzaniem przez nas jego danych osobowych.",
    last: "Poważnie traktujemy ochronę danych osobowych użytkowników i dlatego zawsze jesteśmy dostępni, aby pomóc w przypadku pytań i wątpliwości. Można się z nami skontaktować pod adresem",
  },
  contact: {
    who: "Kim są",
    nocturnalDemons: "Nocturnal Demons",
    paragraph1:
      "został założony w 2022 roku przez wspólną pasję do Drum n Bass i Techno jako kolektyw przyjaciół, aby zapewnić coś świeżego w naszym regionie wokół Görlitz, zwłaszcza Neurofunk.",
    paragraph3:
      "Naszą misją jest wzmocnienie sceny DnB i techno poprzez zapraszanie wschodzących międzynarodowych DJ-ów z Czech i innych krajów, którzy kształtują scenę neurofunk. Łączymy ich z lokalnymi DJ-ami, aby zaoferować naszym gościom skład, który jest tak jakościowy i różnorodny, jak to tylko możliwe, a także platformę dla artystów w Niemczech. Razem przeniesiemy neurofunk przez granicę do Görlitz.",
    team: "Nasz zespół",
  },
};
