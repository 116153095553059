export const czCommon = {
  title: "Mein Profil",
  links: {
    event: "Událost",
    location: "Umístění",
    tickets: "Vstupenky",
    gallery: "Fotografie",
    details: "Událost",
    home: "Home",
    contact: "Kontakt",
    imprint: "Imprint",
    info: "O nás",
    privacy: "Ochrana údajů",
  },
  footer: {
    contact: "Kontakt",
    info: "O nás",
    privacy: "Ochrana údajů",
    imprint: "Imprint",
  },
  events: {
    location: "Nostromo Görlitz",
    date: "23.09",
    genre: "| Drum n Bass | Techno |",
    tickets: "Vstupenky",
    info: "O nás",
    lastEvent: "Poslední událost: 16.09.23",
    seeya: "Další se chystají.",
    nextEvent: "další událost",
    advanceBooking: "Žádná rezervace předem a online prodej",
  },
  location: {
    location: "Next Location",
    addressTitle: "NOSTROMO Schall & Rauch e.V.",
    addressStreet: "Cottbuser Str. 21, 02826 Görlitz",
    date: "23.09 | 20:00",
  },
  details: {
    price: "15€ Entrance fee",
    lineup: "Sestava",
    tickets: "Vstupenky",
    location: "Umístění",
    map: "Mapa",
    acts: "Umělci",
    timetable: "Jízdní řád"
  },
  imprint: {
    imprint: "Imprint",
    tmg: "Gemäß § 5 TMG",
    represent: "Vertreten durch",
    contact: "Kontakt",
    content: "Haftung für Inhalte",
    links: "Haftung für Links",
    validity: "Gültigkeit",
  },
  privacy: {
    title: "Zásady ochrany osobních údajů",
    common: "Obecné informace o shromažďování osobních údajů",
    paragraph1: "Naše webové stránky samy o sobě neshromažďují ani neukládají žádné osobní údaje, nemají žádné uživatelské účty a neukládají žádné soubory cookie. Nepoužívají se ani skripty externích třetích stran za účelem sledování chování uživatelů. ",
    paragraph2: "Naše webové stránky samy o sobě neshromažďují ani neukládají žádné osobní údaje, nemají žádné uživatelské účty a neukládají žádné soubory cookie. Nepoužívají se ani skripty externích třetích stran za účelem sledování chování uživatelů. \n" +
        "Náš hosting vytváří pouze soubory protokolů o požadavcích, které slouží k řešení problémů a nejsou dále analyzovány. Jedná se o metadata prohlížeče, jako je IP adresa, prohlížeč, operační systém, datum a čas požadavku, obsah požadavku (stránky), stavový kód HTTP požadavku, přenesené bajty požadované adresy URL, typ koncového zařízení. Tyto údaje jsou dočasně uchovávány po dobu 14 dnů, aby byl zajištěn bezproblémový provoz webových stránek.",
    hoster: "Hoster",
    paragraph3: "Naším hostitelem je společnost Amazon Web Services (AWS).\n" +
        "Poskytovatelem je Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg.\n" +
        "(dále jen AWS).\n" +
        "Při návštěvě našich webových stránek jsou vaše osobní údaje zpracovávány na serverech společnosti AWS.\n" +
        "Servery: AWS je společnost, která se zabývá poskytováním služeb v oblasti ochrany osobních údajů, a to zejména na serverech, které jsou součástí sítě AWS. Osobní údaje mohou být rovněž předávány mateřské společnosti AWS v USA.\n" +
        "USA. Předávání údajů do USA probíhá na základě standardních smluvních doložek EU.\n" +
        "Podrobnosti naleznete zde:",
    following: "Další informace naleznete v zásadách ochrany osobních údajů společnosti AWS:",
    paragraph4: "Používání AWS je založeno na čl. 6 odst. 1 písm. f DSGVO. Máme oprávněné\n" +
        "zájem na co nejspolehlivější prezentaci našich webových stránek. Pokud se jedná o odpovídající\n" +
        "byl vyžádán souhlas, je zpracování prováděno výhradně na základě čl. 6 odst. 1 písm. a.\n" +
        "DSGVO a § 25 odst. 1 TTDSG, pokud souhlas umožňuje ukládání souborů cookie nebo přístup k nim.\n" +
        "informací v koncovém zařízení uživatele (např. snímání otisků zařízení) ve smyslu TTDSG. Na adrese .\n" +
        "souhlas lze kdykoli odvolat.\n",
    evilStates: "Poznámka k předávání údajů do USA a dalších třetích zemí",
    paragraph5: "Ačkoli hostujeme v regionu AWS EU-Central-1 (Frankfurt), nemůžeme si být jisti ani zaručit a neneseme odpovědnost za to, že by údaje mohly být interně přenášeny také z AWS do USA nebo jiných třetích zemí. Upozorňujeme na to\n" +
        "Rádi bychom upozornili, že v USA nebo jiných třetích zemích nelze zaručit úroveň ochrany údajů srovnatelnou s ochranou údajů v EU.\n" +
        "Například americké společnosti jsou povinny předávat osobní údaje bezpečnostním orgánům bez vašeho souhlasu.\n" +
        "aniž byste vy jako subjekt údajů mohli proti tomu podniknout právní kroky. Nelze tedy\n" +
        "americké orgány (např. zpravodajské služby) zpracovávat, analyzovat a používat vaše údaje na amerických serverech pro účely sledování.\n" +
        "účely sledování, vyhodnocovat je a trvale uchovávat. Tyto skutečnosti nemůžeme nijak ovlivnit\n" +
        "Na tyto činnosti zpracování nemáme žádný vliv.",
    rights: "Práva",
    paragraph6: "Kromě výše uvedených protokolových souborů o vás neukládáme žádné osobní údaje. Přesto máte k dispozici všechna práva, která máte k dispozici. Jedná se o následující práva:",
    right1: "Právo na přístup,",
    right2: "Právo na opravu nebo výmaz,",
    right3: "právo na omezení zpracování,",
    right4: "právo vznést námitku proti zpracování,",
    right5: "právo na přenositelnost údajů.",
    right6: "Máte také právo podat stížnost na námi prováděné zpracování vašich osobních údajů u dozorového úřadu pro ochranu osobních údajů.",
    last: "Ochranu vašich osobních údajů bereme vážně, a proto jsme vám vždy k dispozici v případě dotazů a žádostí. Můžete nás kontaktovat na adrese",
  },
  contact: {
    who: "Kdo jsou",
    nocturnalDemons: " Noční démoni",
    paragraph1:
      "byla založena v roce 2022 společnou vášní pro drum n bass a techno jako kolektiv přátel, aby vám přinesla něco čerstvého v našem regionu kolem Görlitz, zejména neurofunk.",
    paragraph3:
      "Naším posláním je posílit DnB a techno scénu pozváním nadějných zahraničních DJs z České republiky a dalších zemí, kteří formují neurofunkovou scénu. Mícháme je s místními DJs, abychom našim hostům nabídli co nejkvalitnější a nejrozmanitější line-up a zároveň platformu pro umělce v Německu. Společně přivezeme neurofunk za hranice a do Görlitz.",
    team: "Náš tým",
  },
};
